const maxMqlMD = window.matchMedia('(max-width: 767px)');
const minMqlMD = window.matchMedia('(min-width: 768px)');

export default {
  data() {
    return {
      showHorizontalScroll: maxMqlMD.matches
    };
  },
  methods: {
    checkMaxMqlMD($event) {
      if ($event.matches) {
        this.showHorizontalScroll = true;
      }
    },
    checkMinMqlMD($event) {
      if ($event.matches) {
        this.showHorizontalScroll = false;
      }
    }
  },
  mounted() {
    minMqlMD.addListener(this.checkMinMqlMD); // Add the callback function as a listener to the query list.
    maxMqlMD.addListener(this.checkMaxMqlMD); // Add the callback function as a listener to the query list.
  },
  beforeDestroy() {
    minMqlMD.removeListener(this.checkMinMqlMD);
    maxMqlMD.removeListener(this.checkMaxMqlMD);
  }
};
