<template>
  <div>
    <div
      class="row"
      :class="{ 'contacts-row': !showAll }"
      :style="{ 'justify-content': data.items.length === 1 ? 'left' : 'center' }"
    >
      <div class="col-sm-12 horizontal-scrolling-wrapper pb-4" v-if="showHorizontalScroll">
        <!-- <el-carousel arrow="never"> -->
        <template v-for="contact in data.items">
          <contact :contact="contact" :key="contact.index"></contact>
        </template>
        <!-- </el-carousel> -->
      </div>
      <div v-else :class="columnClasses + ' mb-3'" v-for="contact in contacts" :key="contact.index">
        <contact :contact="contact" :full-width="data.displayWidthType > 1"></contact>
      </div>
    </div>
    <div class="row mt-2" v-if="!showHorizontalScroll && data.items.length > this.itemsToShow">
      <div class="col-12">
        <i class="material-icons show-more-toggle pointer" @click="toggleShowAll" v-if="showAll">keyboard_arrow_up</i>
        <i class="material-icons show-more-toggle pointer" @click="toggleShowAll" v-else>keyboard_arrow_down</i>
      </div>
    </div>
  </div>
</template>

<script>
import moduleMixin from '@app/mixins/module';
import horizontalScrollMixin from '@shared/mixins/horizontal-scroll';
import Contact from '../ui/Contact';

export default {
  components: {
    Contact
  },
  mixins: [horizontalScrollMixin, moduleMixin],
  data() {
    return {
      showAll: false,
      itemsToShow: 3
    };
  },
  computed: {
    columnClasses() {
      if (this.data.displayWidthType == 2) {
        // full width
        return 'col-12 col-sm-6 col-md-6 col-lg-4';
      } else {
        // half width
        let columnString = 'col-12';
        return columnString;
      }
    },
    contacts() {
      return this.showAll ? this.data.items : this.data.items.slice(0, this.itemsToShow);
    }
  },
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .contacts-row {
    display: flex;
  }
}

.show-more-toggle {
  font-size: 40px;
}
</style>
