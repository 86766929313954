var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-page-on-dashboard text-left"},[(_vm.data.displayType === 1)?_c('div',[(_vm.showReadMore)?_c('h5',{staticClass:"information-text",domProps:{"innerHTML":_vm._s(_vm.readMoreContent)}}):_c('h5',{staticClass:"information-text",domProps:{"innerHTML":_vm._s(_vm.content)}}),(_vm.showReadMore || (_vm.form && !_vm.data.content) || (_vm.data.items && _vm.data.items.length))?_c('portal',{attrs:{"to":'card-footer-' + _vm.data.id}},[_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"text-right"},[_c('primary-button',{staticClass:"float-right",on:{"click":_vm.readMore}},[_vm._v(_vm._s(_vm._f("translate")('general.readMore')))])],1)])]):_vm._e()],1):_vm._e(),(_vm.data.displayType === 2)?_c('div',[_c('a',{staticClass:"block img-container embed-responsive embed-responsive-2by1",class:{ pointer: (_vm.data.items && _vm.data.items.length) || (_vm.data.content && _vm.data.content.length) },attrs:{"disabled":!_vm.data.items.length && !_vm.data.content},on:{"click":function($event){return _vm.navigate(_vm.data)}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.data.imageURL,"width":"100%"}})])]):_vm._e(),(_vm.data.displayType === 3)?_c('div',{staticClass:"row"},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"pointer grid company-page-images",class:{
        'col-sm-12': _vm.items.length == 1,
        'col-md-6': (_vm.items.length % 4 === 0 || _vm.items.length < 3) && _vm.items.length !== 1,
        'col-md-4':
          (_vm.items.length % 3 === 0 ||
            _vm.items.length % 3 === 2 ||
            (_vm.items.length % 3 == 1 && _vm.items.length !== 4) ||
            _vm.items.length % 2 === 1) &&
          _vm.items.length > 2
      },on:{"click":function($event){return _vm.navigate(item)}}},[_c('figure',{staticClass:"blur img-container embed-responsive embed-responsive-2by1"},[_c('img',{staticClass:"embed-responsive-item",attrs:{"src":item.imageURL,"width":"100%","height":"auto"}}),(item.label && item.label.length)?_c('div',{staticClass:"panel-caption",style:({
            'background-color': _vm.style.companyPageImageCaptionBackgroundColor
              ? _vm.style.companyPageImageCaptionBackgroundColor
              : _vm.style.colorPrimaryButton,
            color: _vm.style.companyPageImageCaptionTextColor
              ? _vm.style.companyPageImageCaptionTextColor
              : _vm.style.colorPrimaryButtonText
          })},[_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(item.label))])]):_vm._e()])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }