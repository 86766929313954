<template>
  <div class="tm-contact card flex-row flex-wrap mt-5 mt-md-0 mt-lg-0" :class="{ 'full-width': fullWidth }">
    <div class="col-md-3 d-flex align-items-center">
      <img @error="imageNotFound" :src="avatar" alt="contact" class="img-fluid rounded-circle contact-image" />
    </div>
    <div
      class="col-md-5 pl-md-1 pt-md-3 pt-lg-3 pt-0 d-flex align-items-center name-col"
      :class="{ 'full-width': fullWidth }"
    >
      <div class="name-container m-auto m-sm-auto mx-md-0 mx-lg-0">
        <p class="font-weight-bold name-field" :style="{ color: style.contactNameTextColor }" :title="name">
          {{ name }}
        </p>
        <p class="mt-1 job-title" :style="{ color: style.contactSubtitleTextColor }" :title="jobTitle" v-if="jobTitle">
          <i class="material-icons mr-2 float-left small" :style="{ color: style.contactSubtitleIconColor }"
            >business_center</i
          >
          {{ jobTitle }}
        </p>
      </div>
    </div>
    <div class="col-md-4 icon-column d-flex align-items-center justify-content-around">
      <button
        class="pointer material-icons"
        :title="contact.email"
        v-if="contact.email"
        aria-label="email contact"
        @click="mailTo(contact.email)"
        :style="{ color: style.contactIconColor }"
      >
        email
      </button>
      <button
        class="pointer material-icons ml-1"
        :title="contact.phoneNumberOffice"
        v-if="contact.phoneNumberOffice"
        aria-label="office phone contact"
        @click="callTo(contact.phoneNumberOffice)"
        :style="{ color: style.contactIconColor }"
      >
        phone
      </button>
      <button
        class="pointer material-icons ml-1"
        v-if="contact.phoneNumberMobile"
        :title="contact.phoneNumberMobile"
        aria-label="office mobile contact"
        @click="callTo(contact.phoneNumberMobile)"
        :style="{ color: style.contactIconColor }"
      >
        smartphone
      </button>
      <button
        class="pointer material-icons ml-1"
        :style="{ color: style.contactIconColor }"
        v-if="contact.movieLink"
        aria-label="movielink contact"
        @click="showVideo()"
      >
        videocam
      </button>
      <button
        class="pointer material-icons ml-1"
        :style="{ color: style.contactIconColor }"
        v-if="contact.textExperience"
        aria-label="contact note"
        @click="showText()"
      >
        note
      </button>
      <a
        :href="contact.linkedinUrl"
        target="_blank"
        v-if="contact.linkedinUrl"
        :title="contact.linkedinUrl"
        style="margin-bottom: 4px; margin-left: 0.25rem"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
          <path
            :style="`fill:${style.contactIconColor};`"
            d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import femaleAvatar from '@shared/assets/img/avatar-w.png';
import maleAvatar from '@shared/assets/img/avatar-m.png';

export default {
  props: {
    contact: {
      type: Object,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('settings', ['style']),
    avatar() {
      const avatar = this.contact && this.contact.gender === 2 ? femaleAvatar : maleAvatar;
      return this.contact.imageURL ? this.contact.imageURL : avatar;
    },
    name() {
      return this.contact.name ? this.contact.name : `${this.contact.firstName} ${this.contact.lastName}`;
    },
    jobTitle() {
      return this.contact.jobTitle ? this.contact.jobTitle : this.contact.functionInCompany;
    },
    video() {
      return this.contact.movieLink
        ? this.contact.movieLink.replace(
            '<iframe',
            '<iframe class="embed-responsive-item" id="contact-video" width="100%"'
          )
        : null;
    }
  },
  data() {
    return {
      showVideoModal: false
    };
  },
  methods: {
    mailTo(email) {
      window.location.href = `mailto:${email}`;
    },
    callTo(phone) {
      window.location.href = `tel:${phone}`;
    },
    imageNotFound(event) {
      event.target.src = this.contact.gender === 2 ? femaleAvatar : maleAvatar;
    },
    showText() {
      const html = this.$sanitize(this.contact.textExperience);
      this.$alert(html, {
        showConfirmButton: false,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        dangerouslyUseHTMLString: true
      }).catch(() => {});
    },
    showVideo() {
      this.$alert(this.video, {
        showConfirmButton: false,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        dangerouslyUseHTMLString: true,
        customClass: 'contact-video-modal'
      }).catch(() => {
        // reset (avoid youtube video to keep playing in the background)
        const videoModal = document.querySelector('.el-message-box__wrapper');
        videoModal.parentElement.removeChild(videoModal);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_font-sizes.scss';

.tm-contact.full-width {
  @media (min-width: 992px) {
    .material-icons {
      font-size: 18px;
    }
  }
}
.tm-contact:not(.full-width) {
  @media (min-width: 992px) {
    .material-icons {
      font-size: 24px;
    }
    .name-col {
      .material-icons {
        font-size: 1.2rem;
      }
    }
  }
}

.tm-contact {
  text-align: left;

  [class^='col-'] {
    padding: 1rem;
  }
  .material-icons {
    font-size: 18px;
    vertical-align: middle;
  }
  p {
    margin-bottom: 0;
  }

  .name-col:not(.full-width) {
    @media (min-width: 992px) {
      @include font-size($secondary);
    }
  }
  .material-icons {
    background: none;
    border: 0;
  }

  .name-container,
  .name-field {
    overflow: hidden;
    word-break: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: list-item;
  }

  .name-field {
    @include font-size($highlight);
  }
  .job-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .material-icons.location {
    font-size: inherit;
  }
  img {
    max-height: 4.2rem;
    margin: auto;
    @media (max-width: 767px) {
      max-height: 6rem;
      margin-top: -4rem;
      background: white;
    }
  }

  .icon-column {
    @media (max-width: 767px) {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
    @media (min-width: 768px) {
      border-left: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  .icon-container {
    display: flex;
    justify-content: space-evenly;
  }

  a {
    color: inherit;
  }
}
</style>
