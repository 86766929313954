<template>
  <div class="card pulse-check-card mb-3">
    <div class="card-body">
      <a href="/">
        <h6 class="card-title text-left font-weight-bold" :style="{ color: style.pulseCheckTitleColor }">
          {{ data.question }}
        </h6>
      </a>
      <div class="row">
        <div class="col-lg-12 col-md-12 px-0 mt-1">
          <el-rate
            class="float-left"
            v-model="data.rating"
            :colors="[style.pulseCheckStarColor, style.pulseCheckStarColor, style.pulseCheckStarColor]"
            disabled
            tabindex="-1"
          ></el-rate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Rate } from 'element-ui';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    elRate: Rate
  },
  computed: {
    ...mapGetters('settings', ['style'])
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_font-sizes.scss';

.pulse-check-card {
  // .card-body {
  //   padding: 1rem;
  //   padding-bottom: 0.75rem;
  //   padding-right: 0.75rem;
  // }
  h6.card-title {
    // font-size: 0.95rem;
    font-weight: 500;
    @include font-size($highlight);
  }
  .card-body .row {
    margin-left: 0;
    margin-right: 0;
  }

  .el-rate {
    height: 2.2rem !important;
    &:focus {
      outline: none;
    }
  }
  .el-rate__icon {
    font-size: 2.2rem !important;
  }
}
</style>
