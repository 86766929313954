var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tm-dashboard-quiz"},[_c('div',[(_vm.data.hasMoreTopItems && !_vm.showHorizontalScroll)?_c('portal',{attrs:{"to":'card-header-action-' + _vm.data.id}},[_c('router-link',{staticClass:"float-right",style:({ color: _vm.style.moduleShowAllTextColor }),attrs:{"to":{ name: 'quizOverview', params: { id: _vm.data.id } }}},[_c('i',{staticClass:"material-icons"},[_vm._v("arrow_forward")]),_c('span',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm._f("translate")('general.showAll')))])])],1):_vm._e(),_c('div',{class:{
        'flex-row row': _vm.data.displayWidthType > 1,
        'horizontal-scrolling-wrapper mx-0': _vm.showHorizontalScroll
      }},_vm._l((_vm.items),function(item){return _c('router-link',{key:item.id,staticClass:"mb-3 pointer",class:{
          'col-12 col-lg-4': _vm.data.displayWidthType > 1,
          'col-12 px-0': _vm.showHorizontalScroll,
          single: _vm.items.length === 1,
          disabled: item.disabled
        },attrs:{"tag":"div","to":{ name: 'quizContent', params: { overview: _vm.data.id, id: item.id } }}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(item.status === _vm.STATUS.TODO)?_c('i',{staticClass:"quiz-icon material-icons float-right",style:({ color: _vm.style.quizIconColor })},[_vm._v("lock_open")]):(item.status === _vm.STATUS.COMPLETED)?_c('i',{staticClass:"quiz-icon material-icons float-right",style:({ color: _vm.style.quizIconColor })},[_vm._v("check_circle")]):_c('i',{staticClass:"quiz-icon material-icons float-right",style:({ color: _vm.style.quizIconColor })},[_vm._v("lock")]),_c('h6',{staticClass:"text-left card-title mb-0",style:({ color: _vm.style.quizTitleColor })},[_vm._v(" "+_vm._s(item.quizTitle)+" ")])]),_c('div',{staticClass:"card-footer bg-white text-left px-3 pt-1 pb-1 mb-2"},[_c('primary-button',{staticStyle:{"min-width":"120px"},attrs:{"size":"small"}},[(item.status === _vm.STATUS.COMPLETED && item.dateFinished)?[_c('span',[_vm._v(_vm._s(_vm._f("translate")('general.view')))])]:(item.status === _vm.STATUS.COMPLETED)?[_vm._v(" "+_vm._s(_vm._f("translate")('quiz.takeQuizAgain'))+" ")]:(item.status === _vm.STATUS.TODO)?[_vm._v(" "+_vm._s(_vm._f("translate")('quiz.take'))+" ")]:[_vm._v(" "+_vm._s(_vm._f("translate")('quiz.locked'))+" ")]],2)],1)])])}),1),(_vm.data.hasMoreTopItems && _vm.showHorizontalScroll)?_c('div',{staticClass:"card-header-action"},[_c('router-link',{staticClass:"float-left",style:({ color: _vm.style.moduleShowAllTextColor }),attrs:{"to":{ name: 'quizOverview', params: { id: _vm.data.id } }}},[_c('i',{staticClass:"material-icons"},[_vm._v("arrow_forward")]),_c('span',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm._f("translate")('general.showAll')))])])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }