import { mapGetters } from 'vuex';

export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters('settings', ['style'])
  }
};
