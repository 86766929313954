<template>
  <div class="card p-2 py-4 flex-md-row flex-sm-column mt-5 mt-md-0 mt-lg-0">
    <div class="col-12 col-md-3 introduction-img d-flex justify-content-center align-items-center">
      <img
        @error="imageNotFound"
        :src="avatar"
        alt="profile-image"
        class="rounded-circle img-fluid dashboard-introduction-img"
        height="auto"
      />
    </div>
    <div class="col-12 col-md-9 introduction-txt text-md-left">
      <h6>{{ introductionText }}</h6>
      <primary-button aria-label="introduce yourself" class="mt-2" @click="goToIntroduction()">
        <template v-if="data.completed">{{ 'general.view' | translate }}</template>
        <template v-else>{{ 'dashboard.introduceYourself' | translate }}</template>
      </primary-button>
    </div>
  </div>
</template>

<script>
import moduleMixin from '../../mixins/module';
import { mapGetters } from 'vuex';

import femaleAvatar from '@shared/assets/img/avatar-w.png';
import maleAvatar from '@shared/assets/img/avatar-m.png';

export default {
  mixins: [moduleMixin],
  computed: {
    ...mapGetters('auth', ['user']),
    avatar() {
      const avatar = this.user.gender === 2 ? femaleAvatar : maleAvatar;
      return this.user && this.user.imageURL && this.user.imageURL.length ? this.user.imageURL : avatar;
    },
    introductionText() {
      if (this.data.completed && this.data.completedText) {
        return this.data.completedText;
      } else if (this.data.hasUserIntroductionText) {
        return this.data.userIntroduction;
      }
      return this.data.defaultText;
    }
  },
  methods: {
    goToIntroduction() {
      this.$router.push({ name: 'introduction', params: { id: this.data.id } });
    },
    imageNotFound(event) {
      event.target.src = this.user.gender === 2 ? femaleAvatar : maleAvatar;
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .dashboard-introduction-img {
    max-height: 90px;
  }
}
@media (max-width: 768px) {
  .introduction-img {
    text-align: center;
    margin-top: -4rem;

    img {
      z-index: 1;
      background: white;
      margin: auto;
      max-height: 6rem;
    }
  }

  .introduction-txt {
    h6 {
      margin-top: 2rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
    button {
      margin: auto;
    }
  }
}
</style>
