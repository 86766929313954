<template>
  <div style="position: relative">
    <div v-if="!mqlMD.matches">
      <i @click="handleMoreInfo" slot="reference" class="material-icons info" v-if="progress < 100">help_outline</i>
      <el-progress :color="style.progressColor" :type="type" :width="85" :stroke-width="8" :percentage="progress">
      </el-progress>
    </div>
    <el-tooltip
      effect="light"
      placement="right-start"
      :class="progress < 100 && 'dashboard-progress'"
      v-if="mqlMD.matches"
      :disabled="progress >= 100"
    >
      <div slot="content">{{ $t('dashboard.progressInfo', { percentage: '100%' }) }}</div>
      <el-progress
        :color="style.progressColor"
        :type="type"
        :width="100"
        :stroke-width="14"
        :percentage="progress"
      ></el-progress>
    </el-tooltip>
    <transition name="fadeHeight">
      <div v-if="enableMoreInfo">
        <p class="progress-info">
          {{ $t('dashboard.progressInfo', { percentage: '100%' }) }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import elProgress from '../ui/CircularProgress';
import { mapGetters } from 'vuex';
const mqlMD = window.matchMedia('(min-width: 768px)');

export default {
  components: {
    elProgress
  },
  props: {
    progress: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('settings', ['style'])
  },
  data() {
    return {
      type: mqlMD.matches ? 'circle' : 'line',
      mqlMD,
      enableMoreInfo: false
    };
  },
  methods: {
    checkMqlMD($event) {
      this.type = $event.matches ? 'circle' : 'line';
    },
    handleMoreInfo() {
      this.enableMoreInfo = !this.enableMoreInfo;
    }
  },
  mounted() {
    mqlMD.addListener(this.checkMqlMD); // Add the callback function as a listener to the query list.
  },
  beforeDestroy() {
    mqlMD.removeListener(this.checkMqlMD);
  }
};
</script>
<style scoped>
.info {
  position: absolute;
  right: 10px;
  top: 13px;
  z-index: 10;
}

.progress-info {
  padding: 0 15px;
  transition: height 5s;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
<style lang="scss">
.el-progress__text {
  zoom: 1.4;
}

#progress,
#progress-mobile {
  .el-progress__text {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 60px;
    }
  }
}
.dashboard-progress.el-progress:hover .el-progress-circle > svg > path.el-progress-circle__path {
  opacity: 0.5;
}

@media (max-width: 575px) {
  .dashboard-progress {
    position: relative;
  }

  .el-progress__text {
    position: absolute;
    left: 50%;
    font-size: 0.8rem !important;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #progress-mobile {
    top: 56px;
  }
  .el-progress__text {
    position: absolute;
    left: 45%;
    font-size: 0.6rem !important;
    margin-left: 0 !important;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  #progress-mobile {
    // position: absolute;

    width: 100%;
    left: 0;
  }
  .info ~ .el-progress {
    padding: 15px 45px 15px 15px;
  }
  .el-progress {
    padding: 15px;
  }
  .el-progress-bar {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  .el-progress-bar__outer,
  .el-progress-bar__inner {
    height: 20px !important;
  }
}
</style>
