<template>
  <div>
    <div class="tm-dashboard-lp" v-if="data.learningPages.length">
      <div v-if="(data.subpagesAppearance && desktop) || data.learningPages.length === 1">
        <div
          class="flex-row row"
          :class="{ 'horizontal-scrolling-wrapper': data.learningPages.length > 1 && showHorizontalScroll }"
        >
          <router-link
            tag="div"
            @click.native="trackPlanhat(item)"
            :to="{ name: 'learningPageContent', params: { module: data.id, id: item.id } }"
            v-for="item in data.learningPages"
            :key="item.id"
            class="mb-4 pointer col-12"
            :class="{
              'col-lg-4 col-md-6':
                data.displayWidthType > 1 &&
                (data.learningPages.length === 3 || (data.learningPages.length > 4 && data.learningPages.length < 7)),
              'col-lg-3 col-md-6':
                data.displayWidthType > 1 && (data.learningPages.length === 4 || data.learningPages.length > 6),
              'col-lg-6 col-md-6':
                (data.displayWidthType > 1 && data.learningPages.length === 2) ||
                (data.displayWidthType === 1 && data.learningPages.length > 1)
            }"
          >
            <div class="card">
              <div class="image-container">
                <img :alt="item.title" class="card-img-top" :src="imgSource(item)" />
              </div>
              <div class="card-body lp-body-125">
                <router-link
                  @click.native="trackPlanhat(item)"
                  :to="{ name: 'learningPageContent', params: { module: data.id, id: item.id } }"
                >
                  <h5 class="font-weight-bold text-left mb-2">{{ item.title }}</h5>
                  <tooltip v-if="item.description.length" :desc="item.description"></tooltip>
                </router-link>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div v-if="!data.subpagesAppearance && desktop && data.learningPages.length > 1 && data.displayWidthType > 1">
        <!-- DESKTOP SWIPE -->
        <swiper :options="swiperOption">
          <swiper-slide v-for="item in data.learningPages" :key="item.id">
            <div class="row">
              <div class="col-md-7 full-width">
                <router-link
                  @click.native="trackPlanhat(item)"
                  :to="{ name: 'learningPageContent', params: { module: data.id, id: item.id } }"
                >
                  <div class="image-container">
                    <img :alt="item.title" :src="imgSource(item)" />
                  </div>
                </router-link>
              </div>
              <router-link
                tag="div"
                class="pointer col-md-5 mt-1 px-4 pl-md-1 mx-2 mx-md-0 mx-lg-0"
                @click.native="trackPlanhat(item)"
                :to="{ name: 'learningPageContent', params: { module: data.id, id: item.id } }"
              >
                <div class="mt-3 mb-4">
                  <h5 class="font-weight-bold text-left">{{ item.title }}</h5>
                  <p class="text-left large-font">{{ item.description }}</p>
                </div>
              </router-link>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div
        v-if="
          (!desktop && data.learningPages.length > 1) ||
            (data.learningPages.length > 1 && !data.subpagesAppearance && data.displayWidthType === 1)
        "
        :class="{ desktop: desktop }"
      >
        <swiper :options="swiperOption">
          <swiper-slide v-for="item in data.learningPages" :key="item.id">
            <router-link
              @click.native="trackPlanhat(item)"
              :to="{ name: 'learningPageContent', params: { module: data.id, id: item.id } }"
            >
              <div class="image-container half-width">
                <img :alt="item.title" :src="imgSource(item)" />
              </div>
            </router-link>

            <router-link
              tag="div"
              @click.native="trackPlanhat(item)"
              :to="{ name: 'learningPageContent', params: { module: data.id, id: item.id } }"
              class="p-3 lp-body-125"
            >
              <h5 class="font-weight-bold text-left">{{ item.title }}</h5>
              <tooltip v-if="item.description.length" :desc="item.description"></tooltip>
            </router-link>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import moduleMixin from '../../mixins/module';
import horizontalScrollMixin from '@shared/mixins/horizontal-scroll';
import planhatMixin from '../../mixins/planhat';
import Tooltip from '../../components/ui/Tooltip';

import { Carousel, CarouselItem } from 'element-ui';
export default {
  created() {
    this.data.learningPages.forEach((item) => {
      if (!item.thumbnailImageUrl) {
        item.thumbnailImageUrl = require('@lp/images/placeholder.png');
      }
    });

    // FIX IE object fit
    setTimeout(() => {
      var images = document.querySelectorAll('img');
      objectFitImages(images);
    }, 1);
  },
  components: {
    elCarousel: Carousel,
    elCarouselItem: CarouselItem,
    Tooltip
  },
  mixins: [horizontalScrollMixin, moduleMixin, planhatMixin],
  computed: {
    swipableContent() {
      return this.data.learningPages[this.activeSlide];
    },
    desktop() {
      return screen.width >= 768;
    }
  },
  data() {
    return {
      activeSlide: 0,
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    };
  },
  methods: {
    updateActiveSlide(index) {
      this.activeSlide = index;
    },
    imgSource(item) {
      return item.thumbnailImageUrl;
    },
    trackPlanhat(item) {
      this.track('tal_learning_page_visited', item.title);
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_helpers.scss';
@import '@shared/styles/all.scss';

// Aspect ratio images for swiper/grid/learn more in learning pages
a:hover {
  text-decoration: none;
}

.image-container {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 50% 0 0 0; // Aspect ratio 2:1

  img {
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  img:not([src]) {
    opacity: 0;
  }
}

.image-container.half-width {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

img {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.large-font {
  font-size: 1rem;
}
[role='tooltip'] {
  max-width: 500px !important;
}
@media (max-width: 576px) {
  [role='tooltip'] {
    max-width: 300px !important;
  }
}
.tm-dashboard-lp {
  .swipable-row {
    padding: 0 !important;
  }

  .swiper-pagination {
    height: 18px;
  }

  .flex-row > div {
    display: flex;
    justify-content: center;
  }

  .card-img-top {
    border-top-right-radius: $general-border-radius;
    border-top-left-radius: $general-border-radius;
  }

  .card {
    border-radius: $general-border-radius;
    width: inherit;

    .card-body {
      padding: 16px;
    }
    @media (min-width: 576px) {
      .lp-body-125 {
        min-height: 125px;
      }
    }
  }
  .card-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
  }

  .el-carousel__container {
    min-height: 400px;
    .el-carousel__item {
      padding: 0;
    }
  }

  .desktop .swiper-container {
    @media (min-width: 576px) {
      .swiper-slide {
        border: 1px solid #dddddd;
        border-radius: $general-border-radius;
        box-shadow: 1px 1px 3px 0px #dddddd;
      }

      .swiper-slide:hover {
        background: $hover-color;
        cursor: pointer;
      }
    }
  }

  .swiper-container {
    .swiper-slide {
      height: auto;
    }

    @media (min-width: 576px) {
      .swiper-slide {
        border: 1px solid #dddddd;
        border-radius: $general-border-radius;
        box-shadow: 1px 1px 3px 0px #dddddd;
      }
      .lp-body-125 {
        min-height: 125px;
      }
    }
  }

  .swiper-container .full-width {
    @media (min-width: 576px) {
      .image-container {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@shared/styles/_helpers.scss';
@import '@shared/styles/all.scss';

.tm-dashboard-lp {
  .swiper-pagination-bullets {
    /* bottom: -47px; */
    left: 0;
    width: 100%;
    position: relative;
    top: 5px;
  }

  @media (max-width: 576px) {
    .swiper-pagination-bullets {
      top: 0px;
    }
  }
}

.tm-dashboard-lp {
  ::v-deep .swiper-pagination-bullets {
    left: 0;
    width: 100%;
    position: relative;
    top: 5px;
  }

  ::v-deep .swiper-pagination-bullet {
    height: 4px;
    width: 35px;
  }

  @media (max-width: 576px) {
    ::v-deep .swiper-pagination-bullets {
      top: 0px;
    }
  }
}
</style>
