<template>
  <div class="form-container">
    <router-link :to="{ name: 'legacyForm', params: { id: data.id } }">
      <div class="text-center">
        <div
          class="d-inline-block form-icon-wrapper"
          :style="{
            background: style.colorPrimaryButton,
            color: style.colorPrimaryButtonText
          }"
        >
          <i class="material-icons">assignment</i>
        </div>
      </div>
      <!-- <img class="img-fluid" src="https://t3.ftcdn.net/jpg/01/22/49/22/240_F_122492242_uia7QOAvKESitl9v4pXGo0CvWmDcHrw1.jpg" width="auto"/> -->
    </router-link>
  </div>
</template>

<script>
import moduleMixin from '../../mixins/module';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('settings', ['style'])
  },
  mixins: [moduleMixin]
};
</script>

<style lang="scss" scoped>
.form-container {
  align-self: center;
  justify-self: center;
  width: 100%;
}

img {
  width: 100%;
  max-width: 100%;
}
.form-icon-wrapper {
  margin: 1rem 1rem 2rem;
  padding: 4rem 4.5rem;
  border-radius: 60%;

  .material-icons {
    font-size: 3rem;
  }
}
</style>
