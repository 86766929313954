<template>
  <div class="company-page-on-dashboard text-left">
    <div v-if="data.displayType === 1">
      <h5 class="information-text" v-html="readMoreContent" v-if="showReadMore"></h5>
      <h5 class="information-text" v-html="content" v-else></h5>
      <portal
        :to="'card-footer-' + data.id"
        v-if="showReadMore || (form && !data.content) || (data.items && data.items.length)"
      >
        <div class="card-footer">
          <div class="text-right">
            <primary-button class="float-right" @click="readMore">{{ 'general.readMore' | translate }}</primary-button>
          </div>
        </div>
      </portal>
    </div>
    <div v-if="data.displayType === 2">
      <a
        class="block img-container embed-responsive embed-responsive-2by1"
        @click="navigate(data)"
        :class="{ pointer: (data.items && data.items.length) || (data.content && data.content.length) }"
        :disabled="!data.items.length && !data.content"
      >
        <img class="img-fluid" :src="data.imageURL" width="100%" />
      </a>
    </div>

    <div v-if="data.displayType === 3" class="row">
      <div
        class="pointer grid company-page-images"
        v-for="item in items"
        :key="item.id"
        @click="navigate(item)"
        :class="{
          'col-sm-12': items.length == 1,
          'col-md-6': (items.length % 4 === 0 || items.length < 3) && items.length !== 1,
          'col-md-4':
            (items.length % 3 === 0 ||
              items.length % 3 === 2 ||
              (items.length % 3 == 1 && items.length !== 4) ||
              items.length % 2 === 1) &&
            items.length > 2
        }"
      >
        <figure class="blur img-container embed-responsive embed-responsive-2by1">
          <img class="embed-responsive-item" :src="item.imageURL" width="100%" height="auto" />
          <div
            class="panel-caption"
            v-if="item.label && item.label.length"
            :style="{
              'background-color': style.companyPageImageCaptionBackgroundColor
                ? style.companyPageImageCaptionBackgroundColor
                : style.colorPrimaryButton,
              color: style.companyPageImageCaptionTextColor
                ? style.companyPageImageCaptionTextColor
                : style.colorPrimaryButtonText
            }"
          >
            <p class="subtitle">{{ item.label }}</p>
          </div>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moduleMixin from '../../mixins/module';

// Display Types
// 1 = Text
// 2 = Image
// 3 = Children

const MAX_WORDS = 60;
const IGNORED_HTML_TAGS = ['p', 'br', 'b', 'i', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong'];

export default {
  mixins: [moduleMixin],
  created() {
    if (this.data.content && this.data.content.indexOf('twitter-timeline') >= 0) {
      let scriptTag = document.createElement('script');
      scriptTag.charset = 'utf-8';
      scriptTag.src = 'https://platform.twitter.com/widgets.js';
      document.head.appendChild(scriptTag);
    }

    this.data.fullContent = this.data.content;

    if (
      this.data.content &&
      /<[a-z/][\s\S]*>/.test(
        this.data.content.replace(new RegExp('<(/?)(' + IGNORED_HTML_TAGS.join('|') + ')( /)?>', 'g'), '')
      )
    ) {
      this.isHtml = true;
    } else {
      this.showReadMore =
        this.data.displayType === 1 && this.data.content && this.data.content.split(' ').length > MAX_WORDS;
      const words = this.data.content ? this.data.content.split(' ') : [];
      if (this.showReadMore) {
        this.data.readMoreContent = words.splice(0, Math.min(words.length, MAX_WORDS)).join(' ');
        this.data.readMoreContent += '...';
      }
    }

    if (this.data.displayType === 1 && this.data.items && this.data.items.length === 1) {
      this.form = this.data.items[0].formId;
    }

    if (this.data.displayType === 3) {
      this.limit = parseInt(this.data.displayWidthType) === 1 ? 6 : Infinity;
    }
  },
  data() {
    return {
      form: null,
      isHtml: false,
      limit: Infinity,
      showReadMore: false
    };
  },
  computed: {
    ...mapGetters('settings', ['style']),
    items() {
      return this.data.items.slice(0, this.limit);
    },
    readMoreContent() {
      return this.$sanitize(this.data.readMoreContent);
    },
    content() {
      return this.$sanitize(this.data.content);
    }
  },
  methods: {
    readMore() {
      if (this.data.items.length) {
        if (this.data.items.length > 1) {
          return this.navigate(this.data);
        }
        this.navigate(this.data.items[0]);
      } else {
        // show modal
        this.$alert(this.data.fullContent, {
          showConfirmButton: false,
          closeOnClickModal: true,
          closeOnPressEscape: true,
          dangerouslyUseHTMLString: true,
          customClass: 'large'
        });
      }
    },
    navigate(item) {
      if (!item.formId) {
        if (item.sectionId) {
          this.$router.push({ name: 'companyPageContent', params: { overview: item.sectionId, id: item.id } });
        } else {
          this.$router.push({ name: 'companyPageOverview', params: { id: item.id } });
        }
      } else {
        this.$http.get(`dashboard/companypages/${item.sectionId}/items/${item.id}`).then(
          (response) => {
            if (response.data && (response.data.description || response.data.htmlContent)) {
              return this.$router.push({
                name: 'companyPageContent',
                params: { overview: item.sectionId, id: item.id }
              });
            }

            this.$router.push({ name: 'legacyForm', params: { id: item.formId } });
          },
          (err) => {
            this.$router.push({ name: 'legacyForm', params: { id: item.formId } });
          }
        );
      }
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_helpers.scss';

.company-page-on-dashboard {
  border-radius: $general-border-radius;
  overflow: hidden;

  .img-container {
    border-radius: $general-border-radius;
    img {
      object-fit: cover;
    }
  }

  .panel-caption {
    border-bottom-left-radius: $general-border-radius;
    border-bottom-right-radius: $general-border-radius;
  }

  .information-text img {
    border-radius: $general-border-radius;
    max-width: 100%;
  }
  .company-page-images {
    margin-bottom: 24px;
  }
}
</style>

<style lang="scss" scoped>
.embed-responsive {
  height: auto;
}

@media (max-width: 767px) {
  .company-page-images {
    padding: 0;
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.panel-caption p.subtitle {
  margin: 0;
}

@media (min-width: 767px) {
  .panel-caption {
    font-size: normal;
  }
}
</style>
